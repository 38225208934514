<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <ASpace>
            <ADatePicker
                v-model:value="state.params.begin_date"
                :disabled-date="disabledStartDate"
                placeholder="Tanggal Mulai"
                style="width:300px;"
                format="DD MMMM YYYY"/>
            <ADatePicker
                v-model:value="state.params.end_date"
                :disabled-date="disabledEndDate"
                placeholder="Tanggal Akhir"
                style="width:300px;"
                format="DD MMMM YYYY"/>
            <FilterDistributorCurah
                v-model:value="state.params.distributor"/>
            <FilterAtbusinessTypeApi
                v-model:value="state.params.type"/>
        </ASpace>
        <ASpace class="mt-2">
            <ASelect
                :options="state.statusLists"
                allow-clear
                style="width: 300px;"
                placeholder="Pilih Status"
                v-model:value="state.params.status"/>
            <!-- <AInputSearch
                v-model:value="state.params.q"
                allow-clear
                placeholder="Cari..."
                style="width:300px"/> -->
            <AButton
                type="primary"
                title="cari"
                @click="fetchDataList"
                :loading="state.isFetching">
                <span
                    v-if="!state.isFetching"
                    class="fa fa-search"
                    aria-hidden="true"/>
                <span v-else>
                    Memuat Data ...
                </span>
            </AButton>
            <AButton
                type="primary"
                title="syncron manual"
                @click="btnAllSync">
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </AButton>
        </ASpace>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
            </div>
            <div class="col-md-12">
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                    <ATag v-if="text === 'sukses'" color="#108ee9">Sukses</ATag>
                    <ATag v-else color="grey">Gagal</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Syncron">
                            <AButton
                                size="small"
                                :loading="record.isSync"
                                @click="btnSyncByID(record)">
                                <i class="fa fa-refresh"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Log History">
                            <AButton
                                size="small"
                                @click="btnDetailLog(record)">
                                <i class="fa fa-history"></i>
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
    </div>

    <!-- form -->
    <AllSync
        v-if="visibleAllSyncModal"
        v-model:visible="visibleAllSyncModal"
        @success="fetchDataList"/>

    <!-- details by id -->
    <DetailLog
        v-if="visibleDetailLogModal"
        v-model:visible="visibleDetailLogModal"
        v-model:item="visibleDetailLogItemModal"/>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterAtbusinessTypeApi from '@/components/filter/FilterAtbusinessTypeApi'
import moment from 'moment'
import AllSync from './AllSync'
import DetailLog from './DetailLog'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
    FilterDistributorCurah,
    FilterAtbusinessTypeApi,
    AllSync,
    DetailLog,
},
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Scheduler',
                    dataIndex: 'trans_date',
                    format: 'DD MMMM YYY',
                },
                {
                    title: 'Fungsi',
                    dataIndex: 'function',
                },
                {
                    title: 'Tipe',
                    dataIndex: 'type',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_text',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Updated Date',
                    dataIndex: 'time',
                },
                {
                    title: 'Total Data',
                    dataIndex: 'data_count',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: '/api/synchrons-curah',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                type: [],
                begin_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()),
                status: null,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                { value: 1, label: 'Sukses' },
                { value: 0, label: 'Gagal' },
            ],
        })

        const disabledStartDate = (current) => {
            return current && current >= moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.begin_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.begin_date) {
                state.params.begin_date = moment(state.params.begin_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return {
                ...updateUrlWithQuery(params),
                status: state.params.status,
            }
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isSync = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // sync data by id
        const btnSyncByID = (record = null) => {
            
            Modal.confirm({
                title: 'Konfirmasi Syncron Data',
                content: 'Apakah Anda ingin syncron data tersebut ?',
                onOk() {
                    record.isSync = true
                    apiClient
                        .post(`/api/synchrons/${record.id}/resynch`, { date: record.trans_date })
                        .then(({ data }) => {
                            message.success('Berhasil disimpan')
        
                            fetchDataList()
                        })
                        .catch(e => message.error('Gagal syncron!'))
                        .finally(() => {
                            record.isSync = false
                        })
                },
                onCancel() { },
            })

        }

        // handle form all sync
        const visibleAllSyncModal = ref(false);
        const btnAllSync = () => {
            visibleAllSyncModal.value = true
        }

        // handle detail log by item
        const visibleDetailLogModal = ref(false);
        const visibleDetailLogItemModal = ref(null);

        const btnDetailLog = (item) => {
            visibleDetailLogModal.value = true
            visibleDetailLogItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            handleTableChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            queryParams,
            visibleAllSyncModal,
            btnAllSync,
            visibleDetailLogModal,
            visibleDetailLogItemModal,
            btnDetailLog,
            btnSyncByID,
        }
    },
})
</script>

<template>
    <AModal
        :visible="visible"
        title="Log History"
        width="90%"
        @cancel="handleModalCancel"
        :footer="null">
        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            size="small"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :pagination="{
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                total: state.meta.total,
                pageSize: state.meta.per_page,
                current: state.meta.page,
            }"
            @change="handleTableChange"
            :loading="state.isFetching">

            <template #status="{ text }">
              <a-tag v-if="text === 1" color="#108ee9">Sukses</a-tag>
              <a-tag v-else color="grey">Gagal</a-tag>
            </template>

            <template #expandedRowRender="{index}">
                <MdTable
                    :columns="state.columnChild"
                    :data-source="[state.data[index]]"
                    :pagination="false">
                </MdTable>
            </template>

        </MdTable>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import moment from 'moment'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: [Object],
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            columns: [
                {
                    title: 'Tgl Trans',
                    dataIndex: 'transdate',
                    customRender: ({ text }) => moment(text).format('DD MMMM YYYY'),
                },
                {
                    title: 'Type',
                    dataIndex: 'type',
                },
                {
                    title: 'Tgl Sync',
                    dataIndex: 'time',
                    customRender: ({ text }) => moment(text).format('DD MMMM YYYY H:mm:ss'),
                },
                {
                    title: 'Note',
                    dataIndex: 'note',
                },
                {
                    title: 'User',
                    dataIndex: 'user',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Count Data',
                    dataIndex: 'data_count',
                },
                {
                    title: 'Duration',
                    dataIndex: 'duration',
                },
            ],
            columnChild: [
                {
                    title: 'Message',
                    dataIndex: 'message',
                },
            ],
            endpoint: `/api/synchrons/${props.item.id}/detail`,
            isFetching: false,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                q: '',
                date: moment(props.item.trans_date).format('YYYY-MM-DD'),
                page: 1,
                "per-page": 10,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:items', [])
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: state.params,
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item, i) => {
                        item.key = i
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            handleModalCancel,
            state,
            handleTableChange,
        }
    },
})
</script>
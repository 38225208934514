<template>
    <a-modal
        :visible="visible"
        title="Synchronize"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- distributor curah -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Distributor Curah"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterDistributorCurah
                            style="width: 100%"
                            v-model:value="form.vendor_id"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- type api -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tipe API"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="type"
                        has-feedback>
                        <FilterAtbusinessTypeApi
                            style="width: 100%"
                            v-model:value="form.type"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- tanggal -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Tanggal Awal"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="from"
                        has-feedback>
                        <ADatePicker
                            v-model:value="form.from"
                            :disabled-date="disabledStartDate"
                            format="DD MMMM YYYY"
                            value-format="YYYY-MM-DD"
                            style="width:100%"
                            placeholder="Pilih Tanggal"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Tanggal Akhir"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="to"
                        has-feedback>
                        <ADatePicker
                            v-model:value="form.to"
                            :disabled-date="disabledEndDate"
                            format="DD MMMM YYYY"
                            value-format="YYYY-MM-DD"
                            style="width:100%"
                            placeholder="Pilih Tanggal"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- note -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Reason"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="note">
                        <AInput
                            stylea="width: 300px;"
                            v-model:value="form.note"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- result -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Result"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        allow-clear/>
                </a-col>
                <a-col :sm="24">
                    <div class="card">
                        <div class="card-body" style="background: #e3e3e3;">
                            <pre id="resultApi"></pre>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">Synchronize</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterAtbusinessTypeApi from '@/components/filter/FilterAtbusinessTypeApi'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterDistributorCurah,
        FilterAtbusinessTypeApi,
    },
    props: {
        visible: [Boolean],
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            from: null,
            to: null,
            vendor_id: [],
            type: [],
            note: '',
        }))

        const formRef = ref();

        const state = reactive({
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                vendor_id: [
                    {
                        required: true,
                        message: "Distributor curah tidak boleh kosong!",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Jenis API tidak boleh kosong!",
                    },
                ],
                note: [
                    {
                        required: true,
                        message: "Reason tidak boleh kosong!",
                    },
                ],
                to: [
                    {
                        required: true,
                        message: "Tanggal akhir tidak boleh kosong!",
                    },
                ],
                from: [
                    {
                        required: true,
                        message: "Tanggal awal tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const disabledStartDate = (current) => {
            const endDate = form.to
            
            // Disable dates after the end_date and dates more than 3 months before the end_date
            return current && (current > moment(endDate) || current < moment(endDate).subtract(3, 'months'))
        }

        const disabledEndDate = (current) => {
            const startDate = form.from
            
            // Disable dates before the start_date and dates more than 3 months after the start_date
            return current && (moment(startDate) >= current || current > moment(startDate).add(3, 'months'))
        }


        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {})

            form.post('/api/synchrons/atbisnis')
                .then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    message.success('Berhasil synchronize.')

                    var resultApi = document.getElementById('resultApi')
                    resultApi.innerHTML = JSON.stringify(data, undefined, 2)

                    form.reset()
                    formRef.value.resetFields()

                    emit('success', data)
                })
        }

        // handle func vue
        onMounted(() => {
            form.reset()
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
